import React, { useState, useEffect } from 'react';

const BookCover = ({ coverUrl, fallbackUrl }) => {
  const [isCoverLoaded, setIsCoverLoaded] = useState(false);
  const [isCoverEmpty, setIsCoverEmpty] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleImageLoad = (event) => {
    const imgElement = event.target;
    const isImageEmpty = imgElement.naturalWidth === 1 && imgElement.naturalHeight === 1;
    setIsCoverEmpty(isImageEmpty);
    setIsCoverLoaded(true);
  };

  const handleImageError = () => {
    setHasError(true);
    setIsCoverLoaded(true);
  };

  const imageStyles = {
    display: !isCoverEmpty && isCoverLoaded ? 'block' : 'none',
  };

  const fallbackStyles = {
    display: isCoverEmpty && isCoverLoaded ? 'block' : 'none',
  };

  return (
    <div>
      <img
        src={coverUrl}
        onLoad={handleImageLoad}
        onError={handleImageError}
        alt="Book Cover"
        style={imageStyles}
      />
      <img
        src={fallbackUrl}
        alt="Fallback Cover"
        style={fallbackStyles}
        onLoad={handleImageLoad}
      />
    </div>
  );
};

export default BookCover;
